import { ReactElement } from 'react';

import {
  AuthenticationTab,
  Client,
  FlowMethod,
  FlowStrategy,
  IconType,
  InputType,
  NodeAction,
  NodeGroup,
  ServerMessageType,
} from '../enum';
import { CARD_SETTINGS } from '../pages/Settings/components/UserAccess/UserAccess';
import { HintsFn } from '../components';

export namespace Maps {
  export const TOOLTIP_MESSAGE_MAP = new Map<
    Client,
    string | (() => ReactElement)
  >([
    [Client.Identifier, 'NRC'],
    [Client.NationalId, 'NRC'],
    [
      Client.WebAuthRegisterDisplayName,
      'Passkeys are, as described by the FIDO Alliance, "a password replacement that provides faster, easier, and more secure sign-ins to websites and apps across a user\'s devices."',
    ],
    [
      Client.Password,
      () =>
        HintsFn(
          CARD_SETTINGS.find((s) => s.flowStrategy === FlowStrategy.Password)
            ?.listHints ?? [],
        ),
    ],
  ]);
  export const INPUT_ICON_MAP = new Map([
    [IconType.Identifier, 'icon-dgpass-user'],
    [IconType.Password, 'icon-dgpass-password'],
    [IconType.Key, 'icon-dgpass-key'],
    [IconType.WebAuthDisplayName, 'icon-dgpass-key'],
  ]);

  export const FORM_FLOW_MAP = new Map([
    [
      FlowStrategy.SignIn,
      [
        NodeGroup.Default,
        NodeGroup.Password,
        NodeGroup.Totp,
        NodeGroup.LookupSecret,
      ],
    ],
    [
      FlowStrategy.SignInWithOauth2,
      [
        NodeGroup.Default,
        NodeGroup.Webauthn,
        NodeGroup.Password,
        NodeGroup.Totp,
        NodeGroup.LookupSecret,
      ],
    ],
    [FlowStrategy.SignInSecurityKey, [NodeGroup.Default, NodeGroup.Webauthn]],
    [FlowStrategy.SignInQRCode, [NodeGroup.QRCode]],
    [FlowStrategy.SignUp, [NodeGroup.Default, NodeGroup.Password]],
    [FlowStrategy.SignUpSecurityKey, [NodeGroup.Default, NodeGroup.Webauthn]],
    [FlowStrategy.Settings, [NodeGroup.Default, NodeGroup.Profile]],
    [FlowStrategy.Password, [NodeGroup.Default, NodeGroup.Password]],
    [
      FlowStrategy.NotificationPreferences,
      [NodeGroup.Default, NodeGroup.NotificationPreferences],
    ],
    [FlowStrategy.LookupSecret, [NodeGroup.Default, NodeGroup.LookupSecret]],
    [FlowStrategy.Webauthn, [NodeGroup.Default, NodeGroup.Webauthn]],
    [FlowStrategy.Totp, [NodeGroup.Default, NodeGroup.Totp]],
  ]);

  export const FLOW_STRATEGY_MAP = new Map([
    [
      FlowStrategy.SignIn,
      {
        [FlowMethod.Password]: FlowStrategy.SignIn,
        [FlowMethod.Webauthn]: FlowStrategy.SignInSecurityKey,
        [FlowMethod.QRCode]: FlowStrategy.SignInQRCode,
      },
    ],
    [
      FlowStrategy.SignUp,
      {
        [FlowMethod.Password]: FlowStrategy.SignUp,
        [FlowMethod.Webauthn]: FlowStrategy.SignUpSecurityKey,
        [FlowMethod.QRCode]: FlowStrategy.SignInQRCode,
      },
    ],
  ]);

  export const MESSAGE_TITLE_MAP = new Map([
    [ServerMessageType.Error, 'Verification code Invalid!'],
    [ServerMessageType.Info, 'Enter Code'],
    [ServerMessageType.Success, 'Congratulations!'],
    [ServerMessageType.Default, 'Enter your username!'],
  ]);

  export const MFA_PRIORITY_MAP = new Map([
    [Client.MobileId, 0],
    [Client.Push, 1],
    [Client.Sms, 2],
    [Client.Email, 3],
    [Client.Authenticator, 99],
  ]);

  export const SIGN_UP_PRIORITY_MAP = new Map([
    [Client.UserName, 1],
    [Client.FirstName, 2],
    [Client.MiddleName, 2],
    [Client.LastName, 3],
    [Client.NpEmail, 4],
    [Client.NpPhone, 5],
    [Client.Gender, 6],
    [Client.Country, 7],
    [Client.BirthDay, 8],
    [Client.Divider, 9],
    [Client.NationalId, 10],
    [Client.Tabs, 11],
    [Client.Password, 12],
    [Client.WebAuthRegisterDisplayName, 13],
    [Client.Method, 14],
  ]);

  export const EMAIL_NOTIFICATION_PRIORITY_MAP = new Map([
    [Client.NpEmailEnable, 1],
    [Client.NpEmail, 2],
    [Client.NpNewEmail, 3],
    [Client.Method, 5],
  ]);

  export const SIGN_IN_PRIORITY_MAP = new Map([
    [Client.Identifier, 1],
    [Client.Tabs, 2],
    [Client.Password, 3],
    [Client.Method, 4],
    [Client.Divider, 5],
  ]);

  export const FLOW_NODES_SIGN_UP = new Map([
    [
      FlowStrategy.SignUp,
      {
        [NodeAction.Remove]: [
          Client.WebAuthRegisterDisplayName,
          Client.WebAuthRegister,
          Client.WebAuthRegisterTrigger,
          Client.Script,
        ],
        [NodeAction.Add]: [Client.Password, Client.Method],
      },
    ],
    [
      FlowStrategy.SignUpSecurityKey,
      {
        [NodeAction.Remove]: [Client.Password, Client.Method],
        [NodeAction.Add]: [
          Client.WebAuthRegisterDisplayName,
          Client.WebAuthRegister,
          Client.WebAuthRegisterTrigger,
          Client.Script,
        ],
      },
    ],
  ]);

  export const FLOW_NODES_SIGN_IN = new Map([
    [
      FlowStrategy.SignIn,
      {
        [NodeAction.Remove]: [],
        [NodeAction.Add]: [Client.Password],
      },
    ],
    [
      FlowStrategy.SignInSecurityKey,
      {
        [NodeAction.Remove]: [Client.Password, Client.Method],
        [NodeAction.Add]: [Client.Method],
      },
    ],
  ]);

  export const FLOW_TABS_MAP = new Map([
    [FlowStrategy.SignIn, FlowStrategy.SignInSecurityKey],
    [FlowStrategy.SignUp, FlowStrategy.SignUpSecurityKey],
  ]);

  export const DIVIDER_NODE = {
    attributes: {
      name: InputType.DIVIDER,
      disabled: false,
      node_type: InputType.DIVIDER,
      type: InputType.DIVIDER,
    },
    meta: {
      label: {
        text: InputType.DIVIDER,
        type: InputType.DIVIDER,
      },
    },
    group: NodeGroup.Default,
    messages: [],
    type: InputType.DIVIDER,
  };

  export const TABS_NODE = {
    attributes: {
      name: InputType.TABS,
      disabled: false,
      node_type: InputType.TABS,
      type: InputType.TABS,
    },
    meta: {
      label: {
        text: InputType.TABS,
        type: InputType.TABS,
      },
    },
    tabs: [
      {
        name: AuthenticationTab.Password,
        active: true,
      },
      {
        name: AuthenticationTab.SecurityKey,
        active: false,
      },
    ],
    group: NodeGroup.Default,
    messages: [],
    type: InputType.TABS,
  };
}
