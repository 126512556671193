import { FC, memo, useEffect, useState } from 'react';
import { RecoveryFlow } from '@ory/client';
import { AxiosError } from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { createRecoveryFlow, getRecoveryFlow } from '../../services/OryService';
import { Flow } from 'components';
import { FlowStrategy, MessageType, ServerMessageType } from 'enum';
import { routes } from '../../models/routes';
import { getMessages, handleGetFlowError, renderMessageByType } from 'helpers';
import { Maps } from '../../constants/';

import styles from './Recovery.module.scss';

const messageTextMap = new Map([
  [ServerMessageType.Default, 'Recover your ZamPass account'],
]);

export const Recovery: FC = memo(() => {
  const [flow, setFlow] = useState<RecoveryFlow>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const message = getMessages(flow?.ui.messages || []);

  const onGetRecoveryFlow = (flowId: string) => {
    getRecoveryFlow(flowId)
      .then(({ data }) => {
        setFlow(data);
      })
      .catch((error: AxiosError) => {
        handleGetFlowError(error, navigate);
      });
  };

  const onCreateRecoveryFlow = () => {
    createRecoveryFlow()
      .then(({ data }) => {
        setFlow(data);
        navigate(`${routes.recoveryPassword}?flow=${data.id}`);
      })
      .catch((error: AxiosError) => {
        handleGetFlowError(error, navigate);
      });
  };

  useEffect(() => {
    const flowId = searchParams.get('flow');
    if (flowId) {
      onGetRecoveryFlow(flowId);
    } else {
      onCreateRecoveryFlow();
    }
  }, []);

  return (
    <div
      className={`${styles.recovery} d-flex justify-content-center align-items-center`}
    >
      <div className="d-flex align-items-center bg-neutral-100 rounded col-11 py-5 p-lg-5 px-3 card-max-width">
        <div className="modal-content">
          <div className="text-center">
            {renderMessageByType(
              Maps.MESSAGE_TITLE_MAP,
              message,
              MessageType.Title,
            )}
          </div>
          <div className="py-1">
            {renderMessageByType(messageTextMap, message, MessageType.Text)}
          </div>
          {flow && <Flow flowStrategy={FlowStrategy.Recovery} flow={flow} />}
        </div>
      </div>
    </div>
  );
});
