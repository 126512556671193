import { FC, memo, BaseSyntheticEvent, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import {
  FrontendApiCreateBrowserLoginFlowRequest,
  FrontendApiCreateBrowserRegistrationFlowRequest,
  LoginFlow,
} from '@ory/client';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import {
  createLoginFlow,
  createRegistrationFlow,
  getLoginFlow,
  getRegistrationFlow,
  updateLoginFlow,
  updateRegistrationFlow,
} from '../../services/OryService';

import { Button, Flow } from 'components';
import { Constants, Maps } from '../../constants';
import { routes } from '../../models/routes';
import {
  createFormBody,
  handleGetFlowError,
  onFilterFlowNodes,
  onFilterNodesByGroup,
} from 'helpers';
import { NodeFlow, Tab } from 'interfaces';
import {
  AuthenticationTab,
  ButtonStyle,
  ButtonType,
  Client,
  FlowMethod,
  FlowStrategy,
  InputType,
  NodeGroup,
  RequestedMethods,
} from 'enum';
import {
  FrontendApiUpdateLoginFlowRequest,
  FrontendApiUpdateRegistrationFlowRequest,
  UiNodeInputAttributesTypeEnum,
} from '@ory/client/api';
import { useLogout } from 'hooks';

import styles from './Authentication.module.scss';

interface ConfigNode {
  flow: LoginFlow;
  tabName: AuthenticationTab;
  flowStrategy: FlowStrategy;
  flowMap: Map<FlowStrategy, { add: Client[]; remove: Client[] }>;
  priorityMap: Map<Client, number>;
}

export const Authentication: FC = memo(() => {
  const navigate = useNavigate();
  const [flow, setFlow] = useState<LoginFlow>();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const initialFlowStrategy =
    location.pathname === routes.registration
      ? FlowStrategy.SignUp
      : FlowStrategy.SignIn;
  const [activeTab, setActiveTab] = useState(initialFlowStrategy);
  const [signUpTab, setSignUpTab] = useState<AuthenticationTab>(
    AuthenticationTab.Password,
  );
  const [signInTab, setSignInTab] = useState<AuthenticationTab>(
    AuthenticationTab.Password,
  );
  const [activeStrategy, setActiveStrategy] = useState(initialFlowStrategy);
  const flowIdInQuery = searchParams.get('flow');
  const loginChallenge = searchParams.get('login_challenge');

  const logoutUrl = useLogout(flow?.requested_aal as RequestedMethods);

  const onUpdateFlowError = (error: AxiosError) => {
    if (error.response?.status === 400) {
      setFlow(error.response?.data);
      return;
    } else if (
      error.response?.status === 410 &&
      error.response.data.use_flow_id
    ) {
      if (activeStrategy === FlowStrategy.SignUp) {
        navigate(
          `${routes.registration}?flow=${error.response.data.use_flow_id}`,
        );
        onRegistration(error.response.data.use_flow_id);
      } else {
        navigate(`${routes.login}?flow=${error.response.data.use_flow_id}`);
        onLogin(error.response.data.use_flow_id);
      }
    } else if (
      error.response?.status === 410 &&
      error.response.data.error.details.redirect_to
    ) {
      window.location.replace(error.response.data.error.details.redirect_to);
    } else {
      handleGetFlowError(error, navigate);
    }
  };

  const onSignUp = (values: { [key: string]: string }) => {
    const formBody = createFormBody(values);
    const flowBody = {
      flow: flow?.id,
      updateRegistrationFlowBody: formBody,
    };
    updateRegistrationFlow(
      flowBody as unknown as FrontendApiUpdateRegistrationFlowRequest,
      Constants.REQUEST_HEADER,
    )
      .then(() => {
        if (!flow?.oauth2_login_request?.request_url) {
          navigate(routes.profileSettings);
        }
      })
      .catch((error) => {
        onUpdateFlowError(error);
      });
  };

  const onSignIn = (values: { [key: string]: string }) => {
    const flowBody = {
      flow: flow?.id,
      updateLoginFlowBody: createFormBody(values),
    };

    updateLoginFlow(
      flowBody as unknown as FrontendApiUpdateLoginFlowRequest,
      Constants.REQUEST_HEADER,
    )
      .then(() => {
        if (!flow?.oauth2_login_request?.request_url) {
          navigate(routes.profileSettings);
        }
      })
      .catch((error: AxiosError) => {
        onUpdateFlowError(error);
      });
  };

  const onSubmit = (values: { [key: string]: string }) => {
    const strategyByTab = Maps.FLOW_STRATEGY_MAP.get(activeTab);
    const method =
      values?.method === FlowMethod.Password
        ? FlowMethod.Password
        : values?.method === FlowMethod.QRCode
        ? FlowMethod.QRCode
        : FlowMethod.Webauthn;

    if (strategyByTab) {
      setActiveStrategy(strategyByTab[method]);
    }

    if (activeTab === FlowStrategy.SignUp) {
      onSignUp(values);
    } else {
      onSignIn(values);
    }
  };

  const onCreateLoginFlow = (tab = FlowStrategy.SignIn) => {
    const flowBody = {
      loginChallenge: flow?.oauth2_login_challenge,
    };

    createLoginFlow(
      flowBody as unknown as FrontendApiCreateBrowserLoginFlowRequest,
      Constants.REQUEST_HEADER,
    )
      .then(({ data }) => {
        setFlow(data);
        setActiveTab(tab);
        setActiveStrategy(tab);
        navigate(`${routes.login}?flow=${data.id}`);
      })
      .catch((error: AxiosError) => {
        handleGetFlowError(error, navigate);
      });
  };

  const onGetLoginFlow = (flowId: string) => {
    getLoginFlow(flowId)
      .then(({ data }) => {
        setFlow(data);
      })
      .catch((error: AxiosError) => {
        onUpdateFlowError(error);
      });
  };

  const onCreateRegistrationFlow = (tab = FlowStrategy.SignUp) => {
    const flowBody = {
      loginChallenge: flow?.oauth2_login_challenge,
    };

    createRegistrationFlow(
      flowBody as unknown as FrontendApiCreateBrowserRegistrationFlowRequest,
      Constants.REQUEST_HEADER,
    )
      .then(({ data }) => {
        setFlow(data);
        setActiveTab(tab);
        setActiveStrategy(tab);
        navigate(`${routes.registration}?flow=${data.id}`);
      })
      .catch((error: AxiosError) => {
        handleGetFlowError(error, navigate);
      });
  };

  const onGetRegistrationFlow = (flowId: string) => {
    getRegistrationFlow(flowId)
      .then(({ data }) => {
        setFlow(data);
      })
      .catch((error: AxiosError) => {
        onUpdateFlowError(error);
      });
  };

  const onChangeTab = (tab: FlowStrategy) => {
    if (tab === FlowStrategy.SignIn) {
      onCreateLoginFlow(tab);
    } else {
      onCreateRegistrationFlow(tab);
    }
    return false;
  };

  const onLogin = (flowId: string | null) => {
    if (loginChallenge) {
      const queryRequest = {
        aal: '',
        refresh: true,
        return_to: '',
        loginChallenge: loginChallenge,
      };
      createLoginFlow(queryRequest, Constants.REQUEST_HEADER)
        .then(({ data }) => {
          if (typeof data === 'string') {
            window.location.replace(data);
          } else {
            navigate(`${routes.login}?flow=${data.id}`);
            onGetLoginFlow(data.id);
          }
        })
        .catch((error) => {
          handleGetFlowError(error, navigate);
        });
    } else {
      if (flowId) {
        onGetLoginFlow(flowId);
      } else {
        onCreateLoginFlow();
      }
    }
  };

  const onRegistration = (flowId: string | null) => {
    if (loginChallenge) {
      const queryRequest = {
        return_to: '',
        loginChallenge: loginChallenge,
      };
      createRegistrationFlow(queryRequest, Constants.REQUEST_HEADER)
        .then(({ data }) => {
          if (typeof data === 'string') {
            window.location.replace(data);
          } else {
            navigate(`${routes.registration}?flow=${data.id}`);
            onGetRegistrationFlow(data.id);
          }
        })
        .catch((error) => {
          handleGetFlowError(error, navigate);
        });
    } else {
      if (flowId) {
        onGetRegistrationFlow(flowId);
      } else {
        onCreateRegistrationFlow();
      }
    }
  };

  const onRemoveNodes = (nodes: [], keys: Client[]) => {
    return nodes.filter(
      (node: NodeFlow) => !keys.includes(node?.attributes?.name as Client),
    );
  };

  const onCreateNodes = (nodes: NodeFlow[], keys: Client[]) => {
    const filterNodesByGroup = onFilterNodesByGroup(
      flow?.ui?.nodes,
      NodeGroup.Webauthn,
    );
    const filteredNodesByKeys = filterNodesByGroup.filter(
      (node: NodeFlow) =>
        keys.includes(node?.attributes?.name as Client) ||
        node?.attributes?.type ===
          (InputType.SCRIPT as UiNodeInputAttributesTypeEnum),
    );
    return [...nodes, ...filteredNodesByKeys];
  };

  const getFlowNodesMap = (
    flowMap: Map<FlowStrategy, { add: Client[]; remove: Client[] }>,
    tabName: AuthenticationTab,
    flowStrategy: FlowStrategy,
  ) => {
    return flowMap.get(
      tabName === AuthenticationTab.Password
        ? flowStrategy
        : Maps.FLOW_TABS_MAP.get(flowStrategy) || flowStrategy,
    );
  };

  const onFilterNodesByStrategy = (configNode: ConfigNode) => {
    const newFlowState = onFilterFlowNodes(
      configNode.flow,
      FlowStrategy.SignUp,
    );
    const nodeSignUp = getFlowNodesMap(
      configNode.flowMap,
      configNode.tabName,
      configNode.flowStrategy,
    );
    const removed = onRemoveNodes(
      newFlowState.ui.nodes,
      nodeSignUp?.remove || [],
    );
    const added = onCreateNodes(removed, nodeSignUp?.add || []);
    const filteredNodesByStrategy =
      configNode.flowStrategy === FlowStrategy.SignIn
        ? [...added, Maps.DIVIDER_NODE, Maps.TABS_NODE]
        : [...added, Maps.TABS_NODE];

    const flowNodes = filteredNodesByStrategy.map((node: NodeFlow) => {
      return node?.attributes?.type === InputType.TABS
        ? {
            ...node,
            priority: configNode.priorityMap.get(
              node?.attributes?.name as Client,
            )
              ? Number(
                  configNode.priorityMap.get(node?.attributes?.name as Client),
                )
              : 99,
            tabs: node?.tabs?.map((tab: Tab) => {
              return {
                ...tab,
                active: tab.name === configNode.tabName,
              };
            }),
          }
        : {
            ...node,
            priority: configNode.priorityMap.get(
              node?.attributes?.name as Client,
            )
              ? Number(
                  configNode.priorityMap.get(node?.attributes?.name as Client),
                )
              : 99,
          };
    });

    return {
      ...newFlowState,
      ui: {
        ...newFlowState.ui,
        nodes: flowNodes.sort(
          (a: NodeFlow, b: NodeFlow) => a.priority! - b.priority!,
        ),
      },
    };
  };

  const checkFlowRequestMethod = (flowState: LoginFlow) => {
    const findedPassword = flowState.ui.nodes.find(
      (node) => node.group === NodeGroup.Password,
    );
    return (
      flowState?.requested_aal === RequestedMethods.Aal1 &&
      !flowState.refresh &&
      findedPassword
    );
  };

  const checkFlowSignInSecurityKey = (flowState: LoginFlow) => {
    const findedPassword = flowState.ui.nodes.find(
      (node) => node.group === NodeGroup.Password,
    );
    const findedWebAuth = flowState.ui.nodes.filter(
      (node) => node.group === NodeGroup.Webauthn,
    );

    return flowState.oauth2_login_request?.request_url ||
      flowState.requested_aal === RequestedMethods.Aal1
      ? FlowStrategy.SignInWithOauth2
      : !findedPassword && findedWebAuth.length === 3
      ? FlowStrategy.SignInSecurityKey
      : FlowStrategy.SignIn;
  };

  const onLogout = () => {
    logoutUrl && window.location.replace(logoutUrl);
  };

  useEffect(() => {
    if (location.pathname === routes.registration) {
      onRegistration(flowIdInQuery);
    } else {
      onLogin(flowIdInQuery);
    }
  }, []);

  return (
    <section className={`${styles.mainBg}`}>
      <div className="container d-flex align-items-center justify-content-center">
        <div className={`${styles.containerBody} my-5 card-max-width`}>
          {flow && flow.requested_aal !== RequestedMethods.Aal2 && (
            <div className="tab-header">
              <div className="d-inline-block w-50">
                <a
                  onClick={() => onChangeTab(FlowStrategy.SignIn)}
                  className={`${
                    styles.link
                  } text-center d-block pb-2 w-100 link-light ${
                    activeTab === FlowStrategy.SignIn ? styles.active : ''
                  }`}
                >
                  Sign In
                </a>
              </div>
              <div className="d-inline-block w-50">
                <a
                  onClick={() => onChangeTab(FlowStrategy.SignUp)}
                  className={`${
                    styles.link
                  } text-center d-block pb-2 w-100 link-light ${
                    activeTab === FlowStrategy.SignUp ? styles.active : ''
                  }`}
                >
                  Sign Up
                </a>
              </div>
            </div>
          )}
          {flow && (
            <div className="sign-block px-2 py-4 mt-4 px-lg-5">
              <div className="tab-body">
                {activeTab === FlowStrategy.SignUp && (
                  <div className="sign-up">
                    <h4 className="text-center mb-4 open-sans-medium">
                      Register a ZamPass account
                    </h4>
                    {flow && (
                      <>
                        <Flow
                          flowStrategy={FlowStrategy.SignUp}
                          onSubmit={onSubmit}
                          flow={onFilterNodesByStrategy({
                            flow: flow,
                            tabName: signUpTab,
                            flowStrategy: FlowStrategy.SignUp,
                            flowMap: Maps.FLOW_NODES_SIGN_UP,
                            priorityMap: Maps.SIGN_UP_PRIORITY_MAP,
                          })}
                          activeStrategy={activeStrategy}
                          onChangeTab={(event: BaseSyntheticEvent) =>
                            setSignUpTab(event.target.name)
                          }
                          loginChallenge={
                            flow?.oauth2_login_request?.request_url
                          }
                        />
                        <div className="my-4">
                          <Link
                            to={routes.firstVerification}
                            className="text-link"
                          >
                            Already signed up? Verify your account here
                          </Link>
                        </div>
                      </>
                    )}
                  </div>
                )}
                {activeTab === FlowStrategy.SignIn && (
                  <div className="sign-in">
                    {flow && (
                      <>
                        <Flow
                          flowStrategy={FlowStrategy.SignIn}
                          onSubmit={onSubmit}
                          flow={
                            checkFlowRequestMethod(flow)
                              ? onFilterNodesByStrategy({
                                  flow: flow,
                                  tabName: signInTab,
                                  flowStrategy: FlowStrategy.SignIn,
                                  flowMap: Maps.FLOW_NODES_SIGN_IN,
                                  priorityMap: Maps.SIGN_IN_PRIORITY_MAP,
                                })
                              : onFilterFlowNodes(
                                  flow,
                                  checkFlowSignInSecurityKey(flow),
                                )
                          }
                          activeStrategy={activeStrategy}
                          onChangeTab={(event: BaseSyntheticEvent) =>
                            setSignInTab(event.target.name)
                          }
                          loginChallenge={
                            flow?.oauth2_login_request?.request_url
                          }
                        />
                        <Flow
                          flowStrategy={FlowStrategy.SignInQRCode}
                          onSubmit={onSubmit}
                          flow={onFilterFlowNodes(
                            flow,
                            FlowStrategy.SignInQRCode,
                          )}
                          activeStrategy={activeStrategy}
                          loginChallenge={
                            flow?.oauth2_login_request?.request_url
                          }
                        />
                      </>
                    )}
                    {flow && flow.requested_aal === RequestedMethods.Aal2 && (
                      <div className="mt-1">
                        <p className="font-weight-medium d-inline align-middle">
                          Something’s not working?{' '}
                        </p>
                        <Button
                          wrapperClassname="d-inline"
                          type={ButtonType.BUTTON}
                          onClick={onLogout}
                          className={`${ButtonStyle.LINK} p-0 text-link-secondary`}
                        >
                          Logout
                        </Button>
                      </div>
                    )}
                    {flow && flow.requested_aal !== RequestedMethods.Aal2 && (
                      <div className="mt-3">
                        <Link
                          to={routes.recoveryPassword}
                          className="text-link"
                        >
                          * Recover account?
                        </Link>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
});
