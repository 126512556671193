import { useState, useCallback, memo, FC, useMemo, useContext } from 'react';

import { Button, Icon, TitleContents } from 'components';
import { ButtonStyle, ButtonType, IconSize } from 'enum';
import { getTodayDate, scrollContent } from 'utils';

import styles from './DeleteAccount.module.scss';
import { AuthContext } from 'store/AuthContext';
import { useNavigate } from 'react-router-dom';
import { routes } from 'models/routes';

const MENU_HEIGHT = 76;
const CONTENT_TITLES = [
  'Access the Account Settings',
  'Save Your Personal Activity Data (Optional)',
  'Initiate the Account Deletion Process ',
  'Confirm the Account Deletion',
];

export const DeleteAccount: FC = memo(() => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  const onClickTitleHandler = useCallback((index: number) => {
    setActiveIndex(index);
    scrollContent(index, MENU_HEIGHT);
  }, []);

  const navigateToDeletePage = useCallback(() => {
    isLoggedIn ? navigate(routes.deleteAccount) : navigate(routes.login);
  }, [isLoggedIn]);

  const today = useMemo(() => getTodayDate(), []);

  return (
    <div className="container delete-account-wrapper py-4">
      <div className={`${styles.row} row lh-lg`}>
        <div
          className={`${styles.content} col-lg-9 col-md-8 col-sm-12 mt-3 mt-sm-0`}
        >
          <h1 className="open-sans-bold">How to delete Account</h1>
          <div className="clearfix mt-3 mt-sm-0">
            <div className="d-inline-block">
              <span className="font-weight-medium">Last update: {today}</span>
            </div>
            <div className="d-inline-block float-end">
              <Button
                onClick={navigateToDeletePage}
                className={ButtonStyle.PRIMARY}
                type={ButtonType.BUTTON}
                name="delete"
              >
                Go to Delete Account page
              </Button>
            </div>
          </div>
          <div className="pt-3">
            <div id="item1" className="info-box">
              <h4 className="open-sans-semibold py-3">
                Step 1: Access the Account Settings
              </h4>
              <p>
                To begin the process of deleting your account, you need to
                access your account settings. Follow these steps:{' '}
              </p>
              <h5 className="open-sans-semibold">1. Log in to Your Account:</h5>
              <ul>
                <li>
                  Open your web browser and go to the login page of our website
                  or app.
                </li>
                <li>Enter your username and password to log in.</li>
              </ul>
              <h5 className="open-sans-semibold">
                2. Navigate to Account Settings:
              </h5>
              <ul>
                <li>
                  Once logged in, locate the user profile icon or your account
                  name in the upper-right corner of the page.
                </li>
                <li>Click on it to open a dropdown menu.</li>
                <li> Select “My profile” from the dropdown options. </li>
              </ul>
            </div>
            <div id="item2" className="info-box">
              <h4 className="open-sans-semibold py-3">
                Step 2: Save Your Personal Activity Data (Optional)
              </h4>
              <p>
                Before deleting your account, you may want to save your personal
                activity data. Follow these steps to download your data:
              </p>
              <h5 className="open-sans-semibold">
                1. Locate the Data Download Option:
              </h5>
              <ul>
                <li>
                  In the Account Settings page, look for a section labeled
                  “Recent activity”.
                </li>
                <li>
                  Find the option for “Download All” for Sign-ins and Consents.
                </li>
              </ul>
              <h5 className="open-sans-semibold">2. Request Data Export:</h5>
              <ul>
                <li>Click on the “Download All” button.</li>
                <li>
                  Our system will process your request and compile your data.
                  This may take a few seconds to several minutes, depending on
                  the volume of data.
                </li>
              </ul>
              <h5 className="open-sans-semibold">3. Download Your Data: </h5>
              <ul>
                <li>
                  Once your data is ready, the browser will automatically
                  download your data.
                </li>
                <li>
                  The downloaded data is in CSV format that can be opened with
                  any text editor or with Microsoft Excel application.
                </li>
              </ul>
            </div>
            <div id="item3" className="info-box">
              <h4 className="open-sans-semibold py-3">
                Step 3: Initiate the Account Deletion Process
              </h4>
              <p>
                Once you are in the account settings, follow these steps to
                start the account deletion process:
              </p>
              <ul>
                <li>
                  In the Account Settings page, look for a section labeled
                  “Delete account”.
                </li>
              </ul>
            </div>
            <div id="item4" className="info-box">
              <h4 className="open-sans-semibold py-3">
                Step 4: Confirm the Account Deletion
              </h4>
              <p>
                In the “Delete account” section, you will need to confirm your
                decision:
              </p>
              <h5 className="open-sans-semibold">
                1. Read the Account Deletion Information:
              </h5>
              <ul>
                <li>
                  Carefully read the information provided about what will happen
                  once your account is deleted. Make sure you understand that
                  this action is permanent and cannot be undone.
                </li>
              </ul>
              <h5 className="open-sans-semibold">2. Confirm Deletion:</h5>
              <ul>
                <li>
                  You may be asked to confirm your decision one final time in a
                  popup window. Click “Delete Account” to proceed.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-12">
          <div className={`sticky-top ${styles.sticky}`}>
            <div className="py-2">
              <div className="d-inline-block align-middle">
                <Icon size={IconSize.SIZE_20} name="icon-dgpass-contents" />
              </div>
              <div className="d-inline-block align-middle px-2">
                <h5 className="font-weight-medium">Table of Contents</h5>
              </div>
            </div>
            <div className={`${styles.headings} bg-white`}>
              <TitleContents
                activeIndex={activeIndex}
                onClick={onClickTitleHandler}
                items={CONTENT_TITLES}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
