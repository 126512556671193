import { FC, memo, useEffect, useState } from 'react';
import { Col, Image, Row, Button } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { getError } from '../../services/OryService';
import errorImage from 'assets/images/spaceman.svg';
import { ErrorGlobal } from 'interfaces';
import { routes } from '../../models/routes';

import styles from './Errors.module.scss';

export const Errors: FC = memo(() => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const id = params.get('id');

  const [error, setError] = useState<ErrorGlobal>();

  useEffect(() => {
    if (id) {
      getError(id).then(({ data }) => {
        data.error.code === 401 && navigate(routes.login);
        setError(data.error);
      });
    }
  }, []);

  return (
    <Row className={`me-0 ms-0 ${styles.container}`}>
      <Col className={styles.image}>
        <Image src={errorImage} />
      </Col>
      <Col className={styles.separator} />
      <Col className={styles.errorContainer}>
        <Row className="flex-column">
          <Col className={styles.status}>{error?.status ?? 'Error'}</Col>
          <Col className={styles.code}>{error?.code}</Col>
          <Col className={styles.message}>{error?.message}</Col>
          <Col className="pt-3">
            <Link to={routes.home}>
              <Button className={styles.button}>Take Me Home</Button>
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  );
});
