import {
  FC,
  memo,
  BaseSyntheticEvent,
  useEffect,
  useContext,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Message } from '../../constants';
import LOGO from '../../assets/images/logo.svg';
import { Button, Checkbox, Spinner } from 'components';
import { ButtonStyle, ButtonType, InputType } from 'enum';
import { createFormDataConsent } from 'helpers';
import { AuthContext } from '../../store/AuthContext';
import { getUserScope, submitUserScope } from '../../services/ConsentService';
import { capitalize } from 'utils';

import styles from './Consent.module.scss';

interface ConsentState {
  accepted: boolean;
  challenge: string;
  grantedScopes: string[];
  remember: boolean;
}

interface UserState {
  clientName: string;
}

export const Consent: FC = memo(() => {
  const [searchParams] = useSearchParams();
  const consentChallenge = searchParams.get('consent_challenge') || '';

  const [formState, setFormState] = useState<ConsentState>({
    accepted: false,
    challenge: consentChallenge,
    grantedScopes: [],
    remember: false,
  });

  const [userScopes, setUserScopes] = useState<string[]>([]);
  const { session } = useContext(AuthContext);
  const [user, setUser] = useState<UserState>();

  const onChangeScopeHandler = (e: BaseSyntheticEvent) => {
    setFormState((currentState) => {
      return {
        ...currentState,
        grantedScopes: currentState.grantedScopes.includes(e.target.name)
          ? currentState.grantedScopes.filter(
              (scope) => scope !== e.target.name,
            )
          : [...currentState.grantedScopes, e.target.name],
      };
    });
  };

  const onChangeRememberHandler = (e: BaseSyntheticEvent) => {
    setFormState((currentState) => {
      return {
        ...currentState,
        remember: e.target.checked,
      };
    });
  };

  const onSubmitHandler = (accepted: boolean) => {
    const { challenge, remember, grantedScopes } = formState;
    submitUserScope(
      formState.challenge,
      createFormDataConsent(
        { Challenge: challenge, Remember: remember, Accepted: accepted },
        grantedScopes,
      ),
      !!session?.identity.traits?.verified,
    )
      .then(({ data }) => {
        window.location.replace(data);
      })
      .catch((error) => {
        toast.error(error.message || Message.DEFAULT_ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    getUserScope(consentChallenge, !!session?.identity.traits?.verified)
      .then(({ data }) => {
        const { clientName } = data;
        if (typeof data === 'string') {
          window.location.replace(data);
        } else {
          setUser({ clientName });
          setUserScopes(data.requestedScopes);
        }
      })
      .catch((error) => {
        toast.error(error.message || Message.DEFAULT_ERROR_MESSAGE);
      });
  }, []);

  return user ? (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="col-11 card-max-width">
        <div
          className={`${styles.content} bg-neutral-100 p-lg-5 py-4 px-3 rounded`}
        >
          <form action="">
            <div className="modal-content">
              <div className="align-self-center">
                <img
                  src={LOGO}
                  alt="ZamPass - National Authentication and Access Control Service"
                  className={`${styles.image} img-fluid`}
                />
              </div>
              <div className="py-3">
                <p
                  className={`${styles.subTitle} text-center open-sans-medium`}
                >
                  Application {user.clientName ?? ''} requests permissions to
                  access your personal data, as follows:
                </p>
              </div>
              {userScopes.length &&
                userScopes.map((scope, index) => (
                  <div key={index} className="py-1">
                    <Checkbox
                      type={InputType.CHECKBOX}
                      name={scope}
                      label={capitalize(scope)}
                      labelClass={`${styles.scopeTitle}`}
                      onChange={onChangeScopeHandler}
                      checked={formState.grantedScopes.includes(scope)}
                    />
                    <hr key={index} className={`${styles.divider}`}></hr>
                  </div>
                ))}
              <div className="py-4">
                <p
                  className={`${styles.subTitle} text-center open-sans-medium`}
                >
                  Do you want to be asked again next time you attempt to sign
                  into the {user.clientName ?? ''}?
                </p>
              </div>
              <div className="py-2">
                <Checkbox
                  type={InputType.CHECKBOX}
                  key="Do not ask me again"
                  name="Do not ask me again"
                  label="Do not ask me again"
                  labelClass={`${styles.scopeTitle}`}
                  onChange={onChangeRememberHandler}
                  checked={formState.remember}
                />
              </div>
              <div className="py-4">
                <Button
                  key="Allow access"
                  name="Allow access"
                  className={`${ButtonStyle.PRIMARY} w-100`}
                  type={ButtonType.BUTTON}
                  onClick={() => onSubmitHandler(true)}
                >
                  Allow Access
                </Button>
              </div>
              <div className="py-1">
                <Button
                  key="Deny Access"
                  name="Deny Access"
                  className={`${ButtonStyle.OUTLINEPRIMARY} w-100`}
                  type={ButtonType.BUTTON}
                  onClick={() => onSubmitHandler(false)}
                >
                  Deny Access
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
});
