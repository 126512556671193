import { memo, FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Message } from '../../constants';
import PageLogo from '../../assets/images/zambia_logo_72x72.webp';
import { routes } from '../../models/routes';

import styles from './Footer.module.scss';

export const Footer: FC = memo(() => {
  const location = useLocation();
  return (
    <footer
      className={`${
        location.pathname === routes.consent ? 'd-none' : ''
      } bg-neutral-750 bottom-0 w-100 pt-2 position-relative`}
    >
      <div className={`${styles.footer} container pt-2 pb-1`}>
        <div className="row justify-content-center">
          <div className="col-md-6 col-lg-6">
            <div className="d-flex justify-content-center">
              <img
                src={PageLogo}
                alt="DotGov Sign"
                className="img-fluid mb-3"
              />
            </div>
            <div className="d-flex justify-content-center">
              <p className="text-center text-white px-3 mb-2 w-50 open-sans-bold">
                ZamPass - National Digital Identity and Authentication Service
              </p>
            </div>
            <div className="d-flex justify-content-center">
              <button className="btn btn-outline-primary semi-bold custom-fs-1 py-2 px-3">
                Leave feedback
              </button>
            </div>
          </div>
          <div className="col-md-3 col-lg-3">
            <p className="text-white open-sans-light">Useful Links</p>
            <hr className={`${styles.divider}`}></hr>
            <ul className="p-0">
              <Link to={routes.about} className="nav-link text-link p-0 my-3">
                About
              </Link>
              <Link
                to={routes.deleteAcountInfo}
                className="nav-link text-link p-0 my-3"
              >
                How to delete my ZamPass account
              </Link>
              <Link
                to={routes.termsAndConditions}
                className="nav-link text-link p-0 my-3"
              >
                Terms and Conditions
              </Link>
              <Link to={routes.faq} className="nav-link text-link p-0 my-3">
                FAQ
              </Link>
              <Link
                to={routes.privacyAndPolicy}
                className="nav-link text-link p-0 my-3"
              >
                Privacy and Cookies Policy
              </Link>
            </ul>
          </div>
          <div className="col-md-3 col-lg-3">
            <p className="mb-2 text-white open-sans-light">Support</p>
            <hr className={`${styles.divider}`}></hr>
            <div className="mb-2">
              <p className="mb-1 text-white">Phone:</p>
              <a className="text-white">+260 </a>
              <a href="tel:+260211428600">211 428 600</a>
              <p className="text-muted">(short code: 2008)</p>
            </div>
            <div className="mb-2">
              <p className="mb-1 text-white">Email:</p>
              <a href="mailto: info@eservices.gov.zm">info@eservices.gov.zm</a>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex text-center bg-primary">
        <div className={`${styles.copyright} container open-sans-medium`}>
          <span className="text-white">{Message.FOOTER_TEXT}</span>
        </div>
      </div>
    </footer>
  );
});
