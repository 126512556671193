import Axios from '../utils/axios';

export const getUserScope = (consentChallenge: string, isVerified: boolean) => {
  return Axios.get(
    `/consent?challenge=${consentChallenge}&isVerified=${isVerified}`,
  );
};

export const submitUserScope = (
  challenge: string,
  payload: FormData,
  isVerified: boolean,
) => {
  return Axios.post(
    `/consent?challenge=${challenge}&isVerified=${isVerified}`,
    payload,
  );
};

export const logoutUserByChallenge = (challenge: string, payload: string) => {
  return Axios.post(`/logout?challenge=${challenge}`, payload);
};

export const getLogoutUserByChallenge = (challenge: string) => {
  return Axios.get(`/logout?challenge=${challenge}`);
};
