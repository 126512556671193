import { useState, useCallback, memo, FC, useMemo } from 'react';
import { Button, Icon, TitleContents } from 'components';

import { ButtonStyle, ButtonType, IconSize } from 'enum';
import { getTodayDate, onDownloadPdf, scrollContent } from 'utils';
import styles from './PrivacyAndPolicy.module.scss';

const PDF_FILENAME = 'zampass_privacy_policy';
const MENU_HEIGHT = 76;
const CONTENT_TITLES = [
  'Data Controller',
  'Types of Data collected',
  'Mode and place of processing the Data',
  'The rights of Users',
  'Additional information about Data collection and processing',
  'Definitions and legal references',
];

export const PrivacyAndPolicy: FC = memo(() => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const onDownloadHandler = useCallback(async () => {
    const downloadElement = document.getElementById(
      'downloadElement',
    ) as HTMLElement;
    await onDownloadPdf(downloadElement, PDF_FILENAME);
  }, []);

  const onClickTitleHandler = useCallback((index: number) => {
    setActiveIndex(index);
    scrollContent(index, MENU_HEIGHT);
  }, []);

  const today = useMemo(() => {
    return getTodayDate();
  }, []);

  return (
    <div className="container privacy-policy-wrapper py-4">
      <div id="downloadElement" className={`${styles.row} row lh-lg`}>
        <div
          className={`${styles.content} col-lg-9 col-md-8 col-sm-12 mt-3 mt-sm-0`}
        >
          <h1 className="open-sans-bold">Privacy and Cookies Policy</h1>
          <div className="clearfix mt-3 mt-sm-0">
            <div className="d-inline-block">
              <span className="font-weight-medium">Last update: {today}</span>
            </div>
            <div className="d-inline-block float-end">
              <Button
                onClick={onDownloadHandler}
                className={ButtonStyle.PRIMARY}
                type={ButtonType.BUTTON}
                name="download"
              >
                Download PDF{' '}
                <Icon
                  className="text-light align-middle"
                  name="icon-dgpass-download"
                />
              </Button>
            </div>
          </div>
          <div className="pt-3">
            <p>Privacy Policy for ZamPass</p>
            <div id="item1" className="info-box">
              <h2 className="open-sans-semibold py-3">1. Data Controller</h2>
              <p>
                At ZamPass, one of our main priorities is the privacy of our
                visitors. This Privacy Policy document contains types of
                information that is collected and recorded by ZamPass and how we
                use it.
              </p>
              <p>
                If you have additional questions or require more information
                about our Privacy Policy, do not hesitate to contact us.
              </p>
              <h4 className="open-sans-semibold py-3">Log Files</h4>
              <p>
                {
                  "ZamPass follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information."
                }
              </p>
              <h4 className="open-sans-semibold py-3">
                Cookies and Web Beacons
              </h4>
              <p>
                {
                  "Like any other website, ZamPass uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information."
                }
              </p>
              <h4 className="open-sans-semibold py-3">Privacy Policies</h4>
              <p>
                You may consult this list to find the Privacy Policy for each of
                the advertising partners of ZamPass.
              </p>
              <p>
                {
                  "Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on ZamPass, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit."
                }
              </p>
              <p>
                Note that ZamPass has no access to or control over these cookies
                that are used by third-party websites.
              </p>
              <h4 className="open-sans-semibold py-3">
                Third Party Privacy Policies
              </h4>
              <p>
                {
                  "ZamPass's Privacy Policy does not apply to third-party websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party for more detailed information. It may include their practices and instructions about how to opt-out of certain options."
                }
              </p>
              <p>
                {
                  "You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites."
                }
              </p>
              <h4 className="open-sans-semibold py-3">
                {"Children's Information"}
              </h4>
              <p>
                Another part of our priority is adding protection for children
                while using the internet. We encourage parents and guardians to
                observe, participate in, and/or monitor and guide their online
                activity.
              </p>
              <p>
                ZamPass does not knowingly collect any Personal Identifiable
                Information from children under the age of 13. If you think that
                your child provided this kind of information on our website, we
                strongly encourage you to contact us immediately and we will do
                our best efforts to promptly remove such information from our
                records.
              </p>
              <h4 className="open-sans-semibold py-3">
                Online Privacy Policy Only
              </h4>
              <p>
                This Privacy Policy applies only to our online activities and is
                valid for visitors to our website with regards to the
                information that they shared and/or collect in ZamPass. This
                policy is not applicable to any information collected offline or
                via channels other than this website.
              </p>
              <h4 className="open-sans-semibold py-3">Consent</h4>
              <p>
                By using our website, you hereby consent to our Privacy and
                Cookies Policy and agree to its terms and conditions.
              </p>
            </div>
            <div id="item2" className="info-box">
              <h2 className="open-sans-semibold py-3">
                2. Types of Data collected
              </h2>
              <p>
                Complete details on each type of Personal Data collected are
                provided in the dedicated sections of this privacy policy or by
                specific explanation texts displayed prior to the Data
                collection.
              </p>
              <p>
                Personal Data may be freely provided by the User, or, in case of
                Usage Data, collected automatically when using ZamPass.
              </p>
              <p>
                Unless specified otherwise, all Data requested by this ZamPass
                is mandatory and failure to provide this Data may make it
                impossible for ZamPass to provide its services. In cases where
                ZamPass specifically states that some Data is not mandatory,
                Users are free not to communicate this Data without consequences
                to the availability or the functioning of the Service.
              </p>
              <p>
                Users who are uncertain about which Personal Data is mandatory
                are welcome to contact the Operator.
              </p>
              <p>
                Any use of Cookies - or of other tracking tools - by ZamPass or
                by the owners of third-party services used by ZamPass serves the
                purpose of providing the Service required by the User, in
                addition to any other purposes described in the present document
                and in the Cookie Policy, if available.
              </p>
              <p>
                {
                  "Users are responsible for any third-party Personal Data obtained, published or shared through ZamPass and confirm that they have the third party's consent to provide the Data to the Operator."
                }
              </p>
            </div>
            <div id="item3" className="info-box">
              <h2 className="open-sans-semibold py-3">
                3. Mode and place of processing the Data
              </h2>
              <h4 className="open-sans-semibold py-3">Methods of processing</h4>
              <p>
                The Operator takes appropriate security measures to prevent
                unauthorized access, disclosure, modification, or unauthorized
                destruction of the Data. The Data processing is carried out
                using computers and/or IT enabled tools, following
                organizational procedures and modes strictly related to the
                purposes indicated. In addition to the Operator, in some cases,
                the Data may be accessible to certain types of persons in
                charge, involved with the operation of ZamPass (administration,
                sales, marketing, legal, system administration) or external
                parties (such as third-party technical service providers, mail
                carriers, hosting providers, IT companies, communications
                agencies) appointed, if necessary, as Data Processors by the
                Operator. The updated list of these parties may be requested
                from the Operator at any time.
              </p>
              <h4 className="open-sans-semibold py-3">
                Legal basis of processing
              </h4>
              <p>
                The Operator may process Personal Data relating to Users if one
                of the following applies:
              </p>
              <ul>
                <li>
                  {
                    'Users have given their consent for one or more specific purposes. Note: Under some legislations the Operator may be allowed to process Personal Data until the User objects to such processing ("opt-out"), without having to rely on consent or any other of the following legal bases. This, however, does not apply, whenever the processing of Personal Data is subject to European data protection law;'
                  }
                </li>
                <li>
                  provision of Data is necessary for the performance of an
                  agreement with the User and/or for any pre-contractual
                  obligations thereof;
                </li>
                <li>
                  processing is necessary for compliance with a legal obligation
                  to which the Operator is subject;
                </li>
                <li>
                  processing is related to a task that is carried out in the
                  public interest or in the exercise of official authority
                  vested in the Operator;
                </li>
                <li>
                  processing is necessary for the purposes of the legitimate
                  interests pursued by the Operator or by a third party.
                </li>
              </ul>
              <p>
                In any case, the Operator will gladly help to clarify the
                specific legal basis that applies to the processing, and in
                particular whether the provision of Personal Data is a statutory
                or contractual requirement, or a requirement necessary to enter
                into a contract.
              </p>
              <h4 className="open-sans-semibold py-3">Place</h4>
              <p>
                {
                  "The Data is processed at the Operator's operating offices and in any other places where the parties involved in the processing are located."
                }
              </p>
              <p>
                {
                  "Depending on the User's location, data transfers may involve transferring the User's Data to a country other than their own. To find out more about the place of processing of such transferred Data, Users can check the section containing details about the processing of Personal Data."
                }
              </p>
              <p>
                If any such transfer takes place, Users can find out more by
                checking the relevant sections of this document or inquire with
                the Operator using the information provided in the contact
                section.
              </p>
              <h4 className="open-sans-semibold py-3">Retention time</h4>
              <p>
                Personal Data shall be processed and stored for as long as
                required by the purpose they have been collected for.
              </p>
              <p>Therefore:</p>
              <ul>
                <li>
                  Personal Data collected for purposes related to the
                  performance of a contract between the Operator and the User
                  shall be retained until such contract has been fully
                  performed.
                </li>
                <li>
                  {
                    "Personal Data collected for the purposes of the Operator's legitimate interests shall be retained as long as needed to fulfill such purposes. Users may find specific information regarding the legitimate interests pursued by the Operator within the relevant sections of this document or by contacting the Operator."
                  }
                </li>
              </ul>
              <p>
                The Operator may be allowed to retain Personal Data for a longer
                period whenever the User has given consent to such processing,
                as long as such consent is not withdrawn. Furthermore, the
                Operator may be obliged to retain Personal Data for a longer
                period whenever required to do so for the performance of a legal
                obligation or upon order of an authority.
              </p>
              <p>
                Once the retention period expires, Personal Data shall be
                deleted. Therefore, the right to access, the right to erasure,
                the right to rectification and the right to data portability
                cannot be enforced after expiration of the retention period.
              </p>
            </div>
            <div id="item4" className="info-box">
              <h2 className="open-sans-semibold py-3">
                4. The rights of Users
              </h2>
              <p>
                Users may exercise certain rights regarding their Data processed
                by the Operator.
              </p>
              <p>In particular, Users have the right to do the following:</p>
              <ul>
                <li>
                  <strong>Withdraw their consent at any time.</strong> Users
                  have the right to withdraw consent where they have previously
                  given their consent to the processing of their Personal Data.
                </li>
                <li>
                  <strong>Object to processing of their Data.</strong> Users
                  have the right to object to the processing of their Data if
                  the processing is carried out on a legal basis other than
                  consent. Further details are provided in the dedicated section
                  below.
                </li>
                <li>
                  <strong>Access their Data.</strong> Users have the right to
                  learn if Data is being processed by the Operator, obtain
                  disclosure regarding certain aspects of the processing and
                  obtain a copy of the Data undergoing processing.
                </li>
                <li>
                  <strong>Verify and seek rectification.</strong> Users have the
                  right to verify the accuracy of their Data and ask for it to
                  be updated or corrected
                </li>
                <li>
                  <strong>Restrict the processing of their Data.</strong> Users
                  have the right, under certain circumstances, to restrict the
                  processing of their Data. In this case, the Operator will not
                  process their Data for any purpose other than storing it.
                </li>
                <li>
                  <strong>
                    Have their Personal Data deleted or otherwise removed.
                  </strong>{' '}
                  Users have the right, under certain circumstances, to obtain
                  the erasure of their Data from the Operator.
                </li>
                <li>
                  <strong>
                    Receive their Data and have it transferred to another
                    controller.
                  </strong>{' '}
                  {
                    "Users have the right to receive their Data in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that the Data is processed by automated means and that the processing is based on the User's consent, on a contract which the User is part of or on pre-contractual obligations thereof."
                  }
                </li>
                <li>
                  <strong>Lodge a complaint.</strong> Users have the right to
                  bring a claim before their competent data protection
                  authority.
                </li>
              </ul>
              <h4 className="open-sans-semibold py-3">
                Details about the right to object to processing
              </h4>
              <p>
                Where Personal Data is processed for a public interest, in the
                exercise of an official authority vested in the Operator or for
                the purposes of the legitimate interests pursued by the
                Operator, Users may object to such processing by providing a
                ground related to their particular situation to justify the
                objection.
              </p>
              <p>
                Users must know that, however, should their Personal Data be
                processed for direct marketing purposes, they can object to that
                processing at any time without providing any justification. To
                learn, whether the Operator is processing Personal Data for
                direct marketing purposes, Users may refer to the relevant
                sections of this document.
              </p>
              <h4 className="open-sans-semibold py-3">
                How to exercise these rights
              </h4>
              <p>
                Any requests to exercise User rights can be directed to the
                Operator through the contact details provided in this document.
                These requests can be exercised free of charge and will be
                addressed by the Operator as early as possible and always within
                one month.
              </p>
            </div>
            <div id="item5" className="info-box">
              <h2 className="open-sans-semibold py-3">
                5. Additional information about Data collection and processing
              </h2>
              <h4 className="open-sans-semibold py-3">Legal action</h4>
              <p>
                {
                  "The User's Personal Data may be used for legal purposes by the Operator in Court or in the stages leading to possible legal action arising from improper use of ZamPass or the related Services."
                }
              </p>
              <p>
                The User declares to be aware that the Operator may be required
                to reveal personal data upon request of public authorities.
              </p>
              <h4 className="open-sans-semibold py-3">
                {"Additional information about User's Personal Data"}
              </h4>
              <p>
                In addition to the information contained in this privacy policy,
                ZamPass may provide the User with additional and contextual
                information concerning particular Services or the collection and
                processing of Personal Data upon request.
              </p>
              <h4 className="open-sans-semibold py-3">
                System logs and maintenance
              </h4>
              <p>
                For operation and maintenance purposes, ZamPass and any
                third-party services may collect files that record interaction
                with ZamPass (System logs) use other Personal Data (such as the
                IP Address) for this purpose.
              </p>
              <h4 className="open-sans-semibold py-3">
                Information not contained in this policy
              </h4>
              <p>
                More details concerning the collection or processing of Personal
                Data may be requested from the Operator at any time. Please see
                the contact information at the beginning of this document.
              </p>
              <h4 className="open-sans-semibold py-3">
                {'How "Do Not Track" requests are handled'}
              </h4>
              <p>
                {
                  'ZamPass does not support "Do Not Track" requests. To determine whether any of the third-party services it uses honor the "Do Not Track" requests, please read their privacy policies.'
                }
              </p>
              <h4 className="open-sans-semibold py-3">
                Changes to this privacy policy
              </h4>
              <p>
                The Operator reserves the right to make changes to this privacy
                policy at any time by giving notice to its Users on this page
                and possibly within ZamPass and/or - as far as technically and
                legally feasible - sending a notice to Users via any contact
                information available to the Operator. It is strongly
                recommended to check this page often, referring to the date of
                the last modification listed at the bottom.
              </p>
              <p>
                {
                  "Should the changes affect processing activities performed on the basis of the User's consent, the Operator shall collect new consent from the User, where required."
                }
              </p>
            </div>
            <div id="item6" className="info-box">
              <h2 className="open-sans-semibold py-3">
                6. Definitions and legal references
              </h2>
              <h4 className="open-sans-semibold py-3">
                Personal Data (or Data)
              </h4>
              <p>
                Any information that directly, indirectly, or in connection with
                other information - including a personal identification number -
                allows for the identification or identifiability of a natural
                person.
              </p>
              <h4 className="open-sans-semibold py-3">Usage Data</h4>
              <p>
                {
                  "Information collected automatically through ZamPass (or third-party services employed in ZamPass), which can include: the IP addresses or domain names of the computers utilized by the Users who use ZamPass, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within ZamPass) and the details about the path followed within ZamPass with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment."
                }
              </p>
              <h4 className="open-sans-semibold py-3">User</h4>
              <p>
                The individual using ZamPass who, unless otherwise specified,
                coincides with the Data Subject.
              </p>
              <h4 className="open-sans-semibold py-3">Data Subject</h4>
              <p>The natural person to whom the Personal Data refers.</p>
              <h4 className="open-sans-semibold py-3">
                Data Processor (or Data Supervisor)
              </h4>
              <p>
                The natural or legal person, public authority, agency or other
                body which processes Personal Data on behalf of the Controller,
                as described in this privacy policy.
              </p>
              <h4 className="open-sans-semibold py-3">
                Data Controller (or Operator)
              </h4>
              <p>
                The natural or legal person, public authority, agency or other
                body which, alone or jointly with others, determines the
                purposes and means of the processing of Personal Data, including
                the security measures concerning the operation and use of
                ZamPass. The Data Controller, unless otherwise specified, is the
                Operator of ZamPass.
              </p>
              <h4 className="open-sans-semibold py-3">Service</h4>
              <p>
                The service provided by ZamPass as described in the relative
                terms (if available) and on ZamPass.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-12">
          <div className={`sticky-top ${styles.sticky}`}>
            <div className="py-2">
              <div className="d-inline-block align-middle">
                <Icon size={IconSize.SIZE_20} name="icon-dgpass-contents" />
              </div>
              <div className="d-inline-block align-middle px-2">
                <h5 className="font-weight-medium">Contents</h5>
              </div>
            </div>
            <div className={`${styles.headings} bg-white`}>
              <TitleContents
                activeIndex={activeIndex}
                onClick={onClickTitleHandler}
                items={CONTENT_TITLES}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
