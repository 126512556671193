import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { Session, SettingsFlow, UiNode } from '@ory/client';
import { UiNodeInputAttributes } from '@ory/client/api';

import { Message } from '../components';
import { Message as MessageText, Maps } from '../constants/';

import {
  Client,
  ConsentOptions,
  Countries,
  FlowStrategy,
  InputType,
  MessageType,
  NodeGroup,
  ServerErrorId,
  ServerMessageType,
} from '../enum';
import { BaseFlow, Error, NodeFlow } from '../interfaces';
import { routes } from '../models/routes';
import { getSession } from '../services/OryService';
import { formatDate } from 'utils';

export const getErrors = (errors: Error[]) => {
  const [error] = errors;
  return error && error.type === ServerMessageType.Error ? error.text : '';
};

export const createFormBody = (
  values: {
    [key: string]: string | boolean | string[];
  },
  withNir = false,
) => {
  let formBody: string[] | string = [];
  for (const property in values) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue =
      typeof values[property] === 'string'
        ? encodeURIComponent(String(values[property]))
        : encodeURIComponent(JSON.stringify(values[property]));
    formBody.push(encodedKey + '=' + encodedValue);
  }
  withNir &&
    formBody.push(`${encodeURIComponent('reset_nir')}=${JSON.stringify(true)}`);
  formBody = formBody.join('&');
  return formBody;
};

export const handleGetFlowError = (
  err: AxiosError,
  navigate: (path: string) => void,
) => {
  switch (err.response?.data.error?.id) {
    case ServerErrorId.Session_aal2_required:
    case ServerErrorId.Session_refresh_required:
      window.location.replace(err.response?.data.redirect_browser_to);
      break;
    case ServerErrorId.Session_already_available:
      getSession()
        .then(() => {
          navigate(routes.profileSettings);
        })
        .catch(() => {
          window.location.replace(
            `${window.REACT_APP_KRATOS_BROWSER_URL}/self-service/login/browser?aal=aal2`,
          );
        });
      break;
    case ServerErrorId.Self_service_flow_return_to_forbidden:
    case ServerErrorId.Self_service_flow_expired:
    case ServerErrorId.Security_csrf_violation:
    case ServerErrorId.Security_identity_mismatch:
      navigate(routes.home);
      break;
    case ServerErrorId.Browser_location_change_required:
      window.location.replace(err.response.data.redirect_browser_to);
      break;
    default:
      toast.error(
        err?.response?.data?.error?.message ||
          MessageText.DEFAULT_ERROR_MESSAGE,
      );
  }
};

export const onUpdateFlowError = (
  error: AxiosError,
  setFlow: (flow: SettingsFlow) => void,
  navigate: (path: string) => void,
) => {
  if (error.response?.status === 400) {
    setFlow(error.response?.data);
    return;
  } else if (
    error.response?.status === 410 &&
    error.response.data.use_flow_id
  ) {
    navigate(`${routes.settings}?flow=${error.response.data.use_flow_id}`);
  } else if (
    error.response?.status === 410 &&
    error.response.data.error.details.redirect_to
  ) {
    toast.error(
      error.response.data.error.message || MessageText.DEFAULT_ERROR_MESSAGE,
    );
    setTimeout(() => {
      window.location.replace(
        window.location.href.replace(window.location.search, ''),
      );
    }, 2000);
  } else {
    handleGetFlowError(error, navigate);
  }
};

export const getMessages = (messages: Error[]) => {
  const [message] = messages;
  return message ? message : null;
};

export const renderMessageByType = (
  messageMap: Map<ServerMessageType, string>,
  messageText: Error | null,
  messageType: MessageType,
) => {
  const newMessage = {
    type: messageText ? messageText.type : ServerMessageType.Default,
    text:
      messageType === MessageType.Title
        ? messageMap.get(
            messageText
              ? (messageText.type as ServerMessageType)
              : ServerMessageType.Default,
          ) || ''
        : messageText
        ? messageText.text
        : messageMap.get(ServerMessageType.Default),
  };
  return <Message type={messageType} message={newMessage} />;
};

export const onFilterFlowNodes = (
  flowDefault: BaseFlow,
  flowStrategy: FlowStrategy,
) => {
  const groups = Maps.FORM_FLOW_MAP.get(flowStrategy);

  const filteredNodes = flowDefault?.ui?.nodes.filter((node: NodeFlow) => {
    return groups?.includes(node?.group as NodeGroup);
  });

  return {
    ...flowDefault,
    ui: {
      ...flowDefault?.ui,
      nodes: filteredNodes,
    },
  };
};

export const onFilterNodesByGroup = (
  nodes: NodeFlow[] = [],
  group: NodeGroup,
) => {
  const filteredNodes = nodes.filter((node: NodeFlow) => {
    return group === node?.group;
  });

  return filteredNodes;
};

export const getCountries = () =>
  Object.entries(Countries)?.map(([, value]) => value);

export const removeEmpty = (obj: object) =>
  Object.entries(obj).reduce(
    (a: { [key: string]: string }, [k, v]) => (v == null ? a : ((a[k] = v), a)),
    {},
  );

export const createFormDataConsent = (
  values: {
    [key: string]: string | boolean;
  },
  grantedScopes: string[],
) => {
  const formdata = new FormData();
  for (const property in values) {
    formdata.append(property, String(values[property]));
  }
  grantedScopes.forEach((item) => {
    formdata.append(ConsentOptions.GrantedScopes, item);
  });
  return formdata;
};

export const resetRadioInputs = (
  nodes: (UiNode & { attributes: UiNodeInputAttributes })[],
): object =>
  nodes
    .filter((node) => node.attributes.type.toString() === InputType.RADIO)
    .reduce((acc, node) => ({ ...acc, [node.attributes.name]: '' }), {});

export const removeObjectValueByKeys = (
  values: {
    [key: string]: string;
  },
  keys: string[],
) => {
  keys.forEach((key: string) => {
    if (values[key]) {
      delete values[key];
    }
  });
  return values;
};

export const getUserInfo = (session: Session) => {
  const verifiedEmail =
    session?.identity?.verifiable_addresses &&
    session?.identity?.verifiable_addresses.length
      ? !!session?.identity?.verifiable_addresses[0].verified
      : false;
  return {
    firstName:
      session?.identity.traits?.firstName || session?.identity.traits?.username,
    lastName: session?.identity.traits?.lastName || '',
    verified: !!session?.identity.traits?.verified,
    verifiedEmail,
  };
};

export const filterFlowByAttributeName = (
  nodes: UiNode[] = [],
  attributeName: string,
): UiNode[] =>
  nodes
    .filter((node) => (node as any).attributes?.name !== attributeName)
    .map((node) =>
      (node as any).attributes?.name === Client.BirthDay
        ? {
            ...node,
            attributes: {
              ...node.attributes,
              value: formatDate(new Date((node as any).attributes.value)),
            },
          }
        : { ...node },
    );
