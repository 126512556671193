export enum TooltipDirection {
  TOP = 'top',
  LEFT = 'left',
  BOTTOM = 'bottom',
  RIGHT = 'right',
  AUTO = 'auto',
}

export enum IconSize {
  SIZE_8 = '8px',
  SIZE_12 = '12px',
  SIZE_13 = '13px',
  SIZE_20 = '20px',
  SIZE_40 = '40px',
  SIZE_46 = '46px',
}

export enum ButtonType {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset',
}

export enum ButtonStyle {
  PRIMARY = 'btn btn-primary',
  OUTLINEPRIMARY = 'btn btn-outline-primary',
  SECONDARY = 'btn btn-secondary',
  LINK = 'btn btn-link',
  DANGER = 'btn btn-danger',
}

export enum ButtonRadiusNone {
  left = 'left',
  right = 'right',
}

export enum ButtonSize {
  SMALL = 'btn-sm',
  LARGE = 'btn-lg',
}

export enum InputType {
  NUMBER = 'number',
  TEXT = 'text',
  DATE = 'date',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  SEARCH = 'search',
  BUTTON = 'button',
  SUBMIT = 'submit',
  EMAIL = 'email',
  TELEPHONE = 'tel',
  URL = 'url',
  RANGE = 'range',
  DATETIMELOCAL = 'datetime-local',
  MONTH = 'month',
  TIME = 'time',
  WEEK = 'week',
  COLOR = 'color',
  HIDDEN = 'hidden',
  SELECT = 'select',
  SCRIPT = 'text/javascript',
  IMG = 'img',
  TABS = 'tabs',
  DIVIDER = 'divider',
  TEXTCODE = 'textCode',
  LINK = 'link',
  PASSWORD = 'password',
  GROUPBUTTONS = 'groupButtons',
  VERIFICATIONEMAIL = 'verificationEmail',
}

export enum IconType {
  Identifier = 'identifier',
  NationalId = 'traits.nationalId',
  Password = 'password',
  Key = 'key',
  WebAuthDisplayName = 'webauthn_register_displayname',
}

export enum FlowStrategy {
  SignIn = 'signin',
  SignInWithOauth2 = 'signInWithOauth2',
  SignInSecurityKey = 'signInSecurityKey',
  SignInQRCode = 'signInQRCode',
  SignUp = 'signup',
  SignUpSecurityKey = 'signUpSecurityKey',
  FirstVerification = 'firstVerification',
  SecondVerification = 'secondVerification',
  Recovery = 'recovery',
  Settings = 'settings',
  Password = 'password',
  NotificationPreferences = 'notification_preferences',
  LookupSecret = 'lookup_secret',
  Webauthn = 'webauthn',
  QRCode = 'qr_code',
  Totp = 'totp',
  PushNotification = 'pushNotification',
  EmailNotification = 'emailNotification',
  SmsNotification = 'smsNotification',
  WatsappNotification = 'watsappNotification',
}

export enum NodeType {
  A = 'a',
  Input = 'input',
}

export enum NodeGroup {
  Default = 'default',
  Webauthn = 'webauthn',
  Password = 'password',
  QRCode = 'qr_code',
  Totp = 'totp',
  Profile = 'profile',
  NotificationPreferences = 'notification_preferences',
  LookupSecret = 'lookup_secret',
}

export enum FlowMethod {
  Webauthn = 'webauthn',
  Password = 'password',
  QRCode = 'qr_code',
}

export enum StrategySignUp {
  GetVerification = 'getVerification',
  CreateVerification = 'createVerification',
  UpdateVerification = 'updateVerification',
}

export enum ServerErrorId {
  Session_aal2_required = 'session_aal2_required',
  Session_refresh_required = 'session_refresh_required',
  Session_already_available = 'session_already_available',
  Self_service_flow_return_to_forbidden = 'self_service_flow_return_to_forbidden',
  Self_service_flow_expired = 'self_service_flow_expired',
  Security_csrf_violation = 'security_csrf_violation',
  Security_identity_mismatch = 'security_identity_mismatch',
  Browser_location_change_required = 'browser_location_change_required',
}

export enum ServerMessageType {
  Error = 'error',
  Success = 'success',
  Info = 'info',
  Default = 'default',
}

export enum InputAttributeName {
  NationalId = 'nationalId',
}

export enum MessageType {
  Title = 'title',
  Text = 'text',
}

export enum DividerType {
  Line = 'line',
  Text = 'text',
}

export enum Client {
  Identifier = 'identifier',
  NationalId = 'traits.nationalId',
  Country = 'traits.country',
  FirstName = 'traits.name.first',
  MiddleName = 'traits.name.middle',
  LastName = 'traits.name.last',
  UserName = 'traits.username',
  Gender = 'traits.gender',
  BirthDay = 'dateOfBirth',
  LookupSecretDisable = 'lookup_secret_disable',
  LookupSecretReveal = 'lookup_secret_reveal',
  LookupSecretRegenerate = 'lookup_secret_regenerate',
  CsrfToken = 'csrf_token',
  Authenticator = 'authenticator',
  MobileId = 'mobileid',
  Push = 'push',
  Sms = 'sms',
  Email = 'email',
  NpEmailEnable = 'np_email_enable',
  NpEmail = 'np_email',
  NpSmsEnable = 'np_sms_enable',
  NpSms = 'np_sms',
  NpNewSms = 'np_new_sms',
  NpPushEnable = 'np_push_enable',
  NpPush = 'np_push',
  NpWatsappEnable = 'np_whatsapp_enable',
  NpWatsapp = 'np_whatsapp',
  NpPhone = 'np_phone',
  Method = 'method',
  Password = 'password',
  WebAuthRegisterDisplayName = 'webauthn_register_displayname',
  WebAuthRegister = 'webauthn_register',
  WebAuthRegisterTrigger = 'webauthn_register_trigger',
  Script = 'script',
  Divider = 'divider',
  Tabs = 'tabs',
  PasswordRemove = 'password_remove',
  NpNewEmail = 'np_new_email',
  NotificationPreferences = 'notification_preferences',
  SendOtp = 'send_otp',
  OtpCode = 'otp_code',
  ResetOtp = 'reset_otp',
  GroupButtons = 'group_buttons',
}

export enum ConsentOptions {
  Accepted = 'Accepted',
  Challenge = 'Challenge',
  GrantedScopes = 'GrantedScopes',
  Remember = 'Remember',
}

export enum AuthenticationTab {
  Password = 'Password',
  SecurityKey = 'Security Key',
}

export enum NodeAction {
  Add = 'add',
  Remove = 'remove',
}

export enum DividerText {
  And = 'and',
  Or = 'or',
}

export enum RequestedMethods {
  Aal1 = 'aal1',
  Aal2 = 'aal2',
}

export enum StatusType {
  Active = 'active',
  Inactive = 'inactive',
  Revoked = 'revoked',
  Successful = 'successful',
  Verified = 'verified',
  Unverified = 'unverified',
}

export enum StatusClass {
  Active = 'status-success',
  Inactive = 'status-inactive',
  Verified = 'status-verified',
  Unverified = 'status-unverified',
}

export enum StatusIconName {
  Active = 'icon-dgpass-active-status',
  Inactive = 'icon-dgpass-inactive-status',
  Unverified = 'icon-dgpass-unverified-status',
}

export enum BrowserName {
  Mozzila = 'Mozilla',
  Chrome = 'Chrome',
  Safari = 'Safari',
  Edge = 'Edge',
  Opera = 'Opera',
}

export enum OperatingSystemName {
  Windows = 'Windows',
  MacOs = 'Mac OS',
  Linux = 'Linux',
  Ios = 'iOS',
  Android = 'Android',
}

export enum DeviceType {
  Desktop = 'Desktop',
  Mobile = 'Mobile',
}

export enum QuestionType {
  Registration = 'Registration',
  Mobile = 'Mobile app',
  Protection = 'Protection of personal information',
  WebPortal = 'Web portal',
  Passwords = 'Passwords',
  Support = 'Support',
}

export enum AlignIcon {
  left = 'left',
  right = 'right',
}

export enum UploaderLocation {
  SideBar = 'sideBar',
  ProfileInformation = 'profileInformation',
}

export enum Countries {
  AF = 'Afghanistan',
  AX = 'Aland Islands',
  AL = 'Albania',
  DZ = 'Algeria',
  AS = 'American Samoa',
  AD = 'Andorra',
  AO = 'Angola',
  AI = 'Anguilla',
  AQ = 'Antarctica',
  AG = 'Antigua and Barbuda',
  AR = 'Argentina',
  AM = 'Armenia',
  AW = 'Aruba',
  AU = 'Australia',
  AT = 'Austria',
  AZ = 'Azerbaijan',
  BS = 'Bahamas',
  BH = 'Bahrain',
  BD = 'Bangladesh',
  BB = 'Barbados',
  BY = 'Belarus',
  BE = 'Belgium',
  BZ = 'Belize',
  BJ = 'Benin',
  BM = 'Bermuda',
  BT = 'Bhutan',
  BO = 'Bolivia',
  BA = 'Bosnia and Herzegovina',
  BW = 'Botswana',
  BV = 'Bouvet Island',
  BR = 'Brazil',
  IO = 'British Indian Ocean Territory',
  BN = 'Brunei Darussalam',
  BG = 'Bulgaria',
  BF = 'Burkina Faso',
  BI = 'Burundi',
  KH = 'Cambodia',
  CM = 'Cameroon',
  CA = 'Canada',
  CV = 'Cape Verde',
  KY = 'Cayman Islands',
  CF = 'Central African Republic',
  TD = 'Chad',
  CL = 'Chile',
  CN = 'China',
  CX = 'Christmas Island',
  CC = 'Cocos Keeling Islands',
  CO = 'Colombia',
  KM = 'Comoros',
  CG = 'Congo',
  CD = 'Congo Democratic Republic',
  CK = 'Cook Islands',
  CR = 'Costa Rica',
  CI = "Côte d'Ivoire",
  HR = 'Croatia',
  CU = 'Cuba',
  CW = 'Curaçao',
  CY = 'Cyprus',
  CZ = 'Czech Republic',
  DK = 'Denmark',
  DJ = 'Djibouti',
  DM = 'Dominica',
  DO = 'Dominican Republic',
  EC = 'Ecuador',
  EG = 'Egypt',
  SV = 'El Salvador',
  GQ = 'Equatorial Guinea',
  ER = 'Eritrea',
  EE = 'Estonia',
  ET = 'Ethiopia',
  FK = 'Falkland Islands',
  FO = 'Faroe Islands',
  FJ = 'Fiji',
  FI = 'Finland',
  FR = 'France',
  GF = 'French Guiana',
  PF = 'French Polynesia',
  TF = 'French Southern Territories',
  GA = 'Gabon',
  GM = 'Gambia',
  GE = 'Georgia',
  DE = 'Germany',
  GH = 'Ghana',
  GI = 'Gibraltar',
  GR = 'Greece',
  GL = 'Greenland',
  GD = 'Grenada',
  GP = 'Guadeloupe',
  GU = 'Guam',
  GT = 'Guatemala',
  GG = 'Guernsey',
  GN = 'Guinea',
  GW = 'Guinea Bissau',
  GY = 'Guyana',
  HT = 'Haiti',
  HM = 'Heard Island Mcdonald Islands',
  VA = 'Holy See Vatican City State',
  HN = 'Honduras',
  HK = 'HongKong',
  HU = 'Hungary',
  IS = 'Iceland',
  IN = 'India',
  ID = 'Indonesia',
  IR = 'Iran',
  IQ = 'Iraq',
  IE = 'Ireland',
  IM = 'Isle Of Man',
  IL = 'Israel',
  IT = 'Italy',
  JM = 'Jamaica',
  JP = 'Japan',
  JE = 'Jersey',
  JO = 'Jordan',
  KZ = 'Kazakhstan',
  KE = 'Kenya',
  KI = 'Kiribati',
  KR = 'Korea, Republic of',
  KP = "Korea, Democratic People's Republic of",
  KW = 'Kuwait',
  KG = 'Kyrgyzstan',
  LA = 'Lao Peoples Democratic Republic',
  LV = 'Latvia',
  LB = 'Lebanon',
  LS = 'Lesotho',
  LR = 'Liberia',
  LY = 'Libyan Arab Jamahiriya',
  LI = 'Liechtenstein',
  LT = 'Lithuania',
  LU = 'Luxembourg',
  MO = 'Macao',
  MG = 'Madagascar',
  MW = 'Malawi',
  MY = 'Malaysia',
  MV = 'Maldives',
  ML = 'Mali',
  MT = 'Malta',
  MH = 'Marshall Islands',
  MQ = 'Martinique',
  MR = 'Mauritania',
  MU = 'Mauritius',
  YT = 'Mayotte',
  MX = 'Mexico',
  FM = 'Micronesia',
  MD = 'Moldova',
  MC = 'Monaco',
  MN = 'Mongolia',
  ME = 'Montenegro',
  MS = 'Montserrat',
  MA = 'Morocco',
  MZ = 'Mozambique',
  MM = 'Myanmar',
  NA = 'Namibia',
  NR = 'Nauru',
  NP = 'Nepal',
  NL = 'Netherlands',
  NC = 'New Caledonia',
  NZ = 'New Zealand',
  NI = 'Nicaragua',
  NE = 'Niger',
  NG = 'Nigeria',
  NU = 'Niue',
  NF = 'Norfolk Island',
  MP = 'Northern Mariana Islands',
  NO = 'Norway',
  OM = 'Oman',
  PK = 'Pakistan',
  PW = 'Palau',
  PS = 'Palestinian Territory',
  PA = 'Panama',
  PG = 'Papua New Guinea',
  PY = 'Paraguay',
  PE = 'Peru',
  PH = 'Philippines',
  PN = 'Pitcairn',
  PL = 'Poland',
  PT = 'Portugal',
  PR = 'Puerto Rico',
  QA = 'Qatar',
  RE = 'Reunion',
  MKD = 'Republic of North Macedonia',
  RO = 'Romania',
  RU = 'Russian Federation',
  RW = 'Rwanda',
  BL = 'Saint Barthelemy',
  SH = 'Saint Helena',
  KN = 'Saint Kitts And Nevis',
  LC = 'Saint Lucia',
  MF = 'Saint Martin',
  PM = 'Saint Pierre And Miquelon',
  VC = 'Saint Vincent And Grenadines',
  WS = 'Samoa',
  SM = 'San Marino',
  ST = 'Sao Tome And Principe',
  SA = 'Saudi Arabia',
  SN = 'Senegal',
  RS = 'Serbia',
  SC = 'Seychelles',
  SL = 'Sierra Leone',
  SG = 'Singapore',
  SK = 'Slovakia',
  SI = 'Slovenia',
  SB = 'Solomon Islands',
  SO = 'Somalia',
  ZA = 'South Africa',
  GS = 'South Georgia And Sandwich Island',
  ES = 'Spain',
  LK = 'Sri Lanka',
  SD = 'Sudan',
  SS = 'South Sudan',
  SR = 'Suriname',
  SJ = 'Svalbard And Jan Mayen',
  SZ = 'Swaziland',
  SE = 'Sweden',
  CH = 'Switzerland',
  SX = 'Sint Maarten (Dutch part)',
  SY = 'Syrian Arab Republic',
  TW = 'Taiwan',
  TJ = 'Tajikistan',
  TZ = 'Tanzania',
  TH = 'Thailand',
  TL = 'TimorLeste',
  TG = 'Togo',
  TK = 'Tokelau',
  TO = 'Tonga',
  TT = 'Trinidad And Tobago',
  TN = 'Tunisia',
  TR = 'Turkey',
  TM = 'Turkmenistan',
  TC = 'Turks And Caicos Islands',
  TV = 'Tuvalu',
  UG = 'Uganda',
  UA = 'Ukraine',
  AE = 'United Arab Emirates',
  GB = 'United Kingdom',
  US = 'United States',
  UM = 'United States Outlying Islands',
  UY = 'Uruguay',
  UZ = 'Uzbekistan',
  VU = 'Vanuatu',
  VE = 'Venezuela',
  VN = 'Vietnam',
  VG = 'Virgin Islands British',
  VI = 'Virgin Islands US',
  WF = 'Wallis And Futuna',
  EH = 'Western Sahara',
  YE = 'Yemen',
  ZM = 'Zambia',
  ZW = 'Zimbabwe',
}
