import { FC, memo, useCallback, useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';

import { Client } from 'enum';

import styles from './VerificationEmail.module.scss';

interface VerificationEmailProps {
  onChange: (otp: string) => void;
  name: string;
}

const RESEND_OTP_TEXT = "If you didn't receive OTP code click ";

export const VerificationEmail: FC<VerificationEmailProps> = memo(
  ({ name, onChange }) => {
    const [seconds, setSeconds] = useState<number>(60);
    let currentTimer: NodeJS.Timer;
    const [otp, setOtp] = useState<string>('');

    const onChangeHandler = useCallback((otpCode: string) => {
      setOtp(otpCode);
      onChange(otpCode);
    }, []);

    const startTimmer = useCallback(() => {
      let s = 60;
      currentTimer = setInterval(() => {
        setSeconds(s - 1);
        s -= 1;
        if (s === 1) {
          clearInterval(currentTimer);
        }
      }, 1000);
    }, []);

    const resetOtpHandler = useCallback(() => {
      const otpContainer = document.querySelectorAll('.otp-input input');
      otpContainer &&
        otpContainer.forEach((input) => {
          input.removeAttribute('required');
        });
      setOtp('');
      onChange('');
      currentTimer && clearInterval(currentTimer);
      startTimmer();
    }, []);

    useEffect(() => {
      const otpContainer = document.querySelectorAll('.otp-input input');
      otpContainer.forEach((input) => {
        input.setAttribute('required', 'true');
      });
      startTimmer();
      return () => clearInterval(currentTimer);
    }, []);

    return (
      <div>
        <p className="py-2">
          An email containing a verification code has been sent to your address.
          Please enter the code below to confirm your email.
        </p>
        {seconds > 1 && (
          <>
            <div className="d-flex justify-content-center py-3">
              <div className="d-inline-block">
                <i
                  className={`icon-dgpass-clock ${styles.clock}`}
                  style={{ fontSize: '54px' }}
                />
              </div>
              <div className={`d-inline-block px-3 ${styles.time}`}>
                <span className="min">0 min</span>
                <span className="seconds px-2">{seconds} sec</span>
              </div>
            </div>
            <p className={`text-center ${styles.verification}`}>
              Verification Code
            </p>
            <div className="d-flex">
              <div className="m-auto">
                <div className={`py-3 otp-input ${styles.otpInput}`}>
                  <OtpInput
                    value={otp}
                    onChange={onChangeHandler}
                    numInputs={6}
                    renderSeparator={
                      <span
                        className={`d-block mx-2 ${styles.separator}`}
                      ></span>
                    }
                    renderInput={(props) => <input {...props} />}
                    inputType="number"
                  />
                </div>
                <div className="d-none">
                  <input
                    id={name}
                    className="form-control form-control"
                    aria-label={name}
                    data-testid={name}
                    type="hidden"
                    name={name}
                    value={otp}
                    required={true}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        <div className="d-flex">
          <p className={styles.resend}>
            {RESEND_OTP_TEXT}
            <button
              onClick={resetOtpHandler}
              name={Client.ResetOtp}
              type="submit"
            >
              resend OTP Code
            </button>
          </p>
        </div>
      </div>
    );
  },
);
